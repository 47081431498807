<template>
  <v-card>
    <v-list>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon :color="color" class="mr-3">{{ icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title style="font-weight:bold;line-height: 2em">{{ text }}</v-list-item-title>
          <v-list-item-subtitle>{{ course.title }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="status === 'processed'">
            <span v-if="pendingLoad" style="white-space:normal">This change has been completed and updated in Banner, but has not loaded into the Portal yet. Please check again in a few hours to see this change reflected on your schedule.</span>
            <span v-else style="white-space:normal">This change has been processed and should be reflected in your schedule.</span>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="status === 'approved'" style="white-space:normal">This change has been approved by your instructor and advisor and is awaiting processing by the Records office.</v-list-item-subtitle>
          <template v-else>
            <v-list-item-subtitle v-if="status === 'returned'" style="white-space:normal">This change has been returned to you to make additional changes. Please see the timeline to the right or bottom for additional information.</v-list-item-subtitle>
            <v-list-item-subtitle v-for="{ _id, category, date, name } in approvals" :key="_id">
              <div style="font-weight:bold;width:270px">{{ category + ' Approval' + (name ? ' (' + name + ')' : '') }}: </div>
              <div class="ml-4">{{ date == null ? 'Pending' : 'Approved ' + stringFormatDate(date) }}</div>
            </v-list-item-subtitle>
          </template>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card-actions v-if="!['processed','approved','cancelled','completed'].includes(status)">
      <v-btn color="error" outlined @click="cancel()">
        <v-icon left>fas fa-times-circle</v-icon>
        Cancel Change
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { computed } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  props: {
    action: String,
    status: String,
    course: Object,
    approvals: Array,
    scheduleUpdatedAt: String
  },
  setup (props, { emit }) {
    const icon = computed(() => {
      let text = props.status === 'pending' ? 'fal ' : 'fas '
      switch (props.action) {
        case 'add':
          return text + 'fa-plus-circle'
        case 'drop':
          return text + 'fa-minus-circle'
        case 'change':
          return text + 'fa-exclamation-circle'
      }
    })
    const color = computed(() => {
      switch (props.action) {
        case 'add':
          return 'success'
        case 'drop':
          return 'error'
        case 'change':
          return 'info'
      }
    })
    const text = computed(() => {
      switch (props.action) {
        case 'add':
          return 'Adding Course'
        case 'drop':
          return 'Dropping Course'
        case 'change':
          return 'Changing Course Grade Mode'
      }
    })
    const isCompleted = computed(() => props.approvals.filter(({ date }) => date == null).length === 0)
    const pendingLoad = computed(() => {
      const dt = new Date(props.scheduleUpdatedAt)
      const { date } = props.approvals.find(({ category }) => category === 'Records')
      // If there is a date on the Records approval for this change, then that means they have processed it.
      // If that date is after the last time the schedule was updated, then show it as still pending because that means it hasn't been loaded
      // into the Portal and won't be reflected on the schedule view tabs.
      if (date && new Date(date) > dt) return true
      return false
    })

    async function cancel () {
      if (confirm('Are you sure you want to cancel this schedule change request?')) {
        // Delete change
        emit('cancel')
      }
    }

    return {
      icon,
      color,
      text,
      isCompleted,
      pendingLoad,
      cancel,
      stringFormatDate
    }
  }
}
</script>
